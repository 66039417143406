import { request, requestJSON } from "../utils/axios";
import { stringify } from "qs";

export function list(params) {
  return request({
    url: `/wuan/admin/wx/moneyEnvelope/cover/list?${stringify(params)}`,
    method: "get",
  });
}

export function save(params) {
  return request({
    url: `/wuan/admin/wx/moneyEnvelope/cover/save?${stringify(params)}`,
    method: "POST",
  });
}
