<script setup>
import { onMounted, ref } from "vue";
import { list, save } from "@/api/cover.js";
import { message } from "ant-design-vue";

const coverUrls = ref("");
const coverTotals = ref({ redeemed: 0, unchanged: 0 });

const confirm = async () => {
  const { msg, status } = await save({ urlList: coverUrls.value });
  status == "200" ? message.success(msg) : message.warning(msg);
  loadTotals();
};
const loadTotals = async () => {
  const { msg, status, data } = await list({ pageNum: 1, pageSize: 20 });
  const { used, unused } = data;
  coverTotals.value = { redeemed: used, unchanged: unused };
  status == "200" ? (coverUrls.value = "") : message.warning(msg);
};

onMounted(loadTotals);
</script>
<template>
  <h3>红包封面上传</h3>
  <p>
    已兑换封面：<span style="color: red">{{ coverTotals.redeemed }}</span>
    未兑换封面：<span style="color: green">{{ coverTotals.unchanged }}</span>
  </p>
  <a-textarea v-model:value="coverUrls" :rows="6" />
  <a-button style="margin-top: 10px" type="primary" @click="confirm"
    >提交</a-button
  >
</template>
